import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { InputBaseComponent } from '../input.base';

@Component({
  selector: 'app-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./../input.base.scss']
})
export class InputNumberComponent extends InputBaseComponent implements OnChanges {
  @Input() public min: number | null = null;
  @Input() public max: number | null = null;
  @Input() public step = 1;
  @Input() public allowDecimals = true;
  @Input() public onlyPositives = false;
  @Input() public readonly: boolean | undefined = undefined;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.onlyPositives && !this.min) {
      this.min = changes.onlyPositives.currentValue ? 0 : null;
    }
  }
}
