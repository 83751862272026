import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { CheckDeviceService } from '@farm-portal/core/check-device/check-device.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs';

@Component({
  selector: 'app-mobile-info-header',
  templateUrl: './mobile-info-header.component.html',
  styleUrls: ['./mobile-info-header.component.scss']
})
export class MobileInfoHeaderComponent {
  private googlePlayStoreLink = 'https://play.google.com/store/apps/details?id=eu.farmcloud.farmportalmobile';
  private appleStoreLink = 'https://apps.apple.com/pl/app/farmportal-mobile/id6743080261';
  private readonly DEFAULT_LANG = 'pl';

  constructor(
    private readonly checkDeviceService: CheckDeviceService,
    private readonly translateService: TranslateService
  ) {}

  public navigateToAppStoreLink() {
    const appLang = this.translateService.currentLang;

    this.checkDeviceService.deviceType$.pipe(take(1)).subscribe(deviceType => {
      if ((deviceType === 'android' || deviceType === 'unknown') && this.googlePlayStoreLink) {
        window.open(this.googlePlayStoreLink);
        return;
      }

      if (deviceType === 'ios' && this.appleStoreLink) {
        const linkLang = appLang === this.DEFAULT_LANG ? this.DEFAULT_LANG : 'en';
        window.open(`${this.appleStoreLink}?l=${linkLang}`);
        return;
      }
    });
  }
}

@NgModule({
  imports: [CommonModule, TranslateModule],
  exports: [MobileInfoHeaderComponent],
  declarations: [MobileInfoHeaderComponent]
})
export class MobileInfoHeaderModule {}
