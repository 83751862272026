export const MAP_CONTOUR_COLOR = '#eeee55';
export const HELPER_CONTOUR_COLOR = '#cb212f ';
export const EXISTING_ZONE_COLOR = '#124CFF';
export const EDITABLE_ZONE_COLOR = '#ffffff';
export const HIGHLIGHTED_ZONE_COLOR = '#fa0b65';
export const ZONE_COLOR_FILL = '#ffffff';

export const CENTER_OF_POLAND_LAT_LNG = {
  lat: 51.9189046,
  lng: 19.1343786
};
