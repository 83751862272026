import { Component, OnInit } from '@angular/core';
import { InputBaseComponent } from '../input.base';
import { PhoneValidator } from '@farm-portal/shared/validators/phone.validator';

@Component({
  selector: 'app-input-phone-number',
  templateUrl: './input-phone-number.component.html',
  styleUrls: ['./../input.base.scss']
})
export class InputPhoneNumberComponent extends InputBaseComponent implements OnInit {
  ngOnInit() {
    this.setControlValidation();
  }

  private setControlValidation(): void {
    const existingValidator = this.inputControl.validator;
    this.inputControl.setValidators(existingValidator ? [existingValidator, PhoneValidator.format()] : [PhoneValidator.format()]);
    this.inputControl.updateValueAndValidity();
  }
}
