import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { MobileDeviceType } from './mobile-device-type.type';

@Injectable({
  providedIn: 'root'
})
export class CheckDeviceService {
  private deviceTypeSubject = new BehaviorSubject<MobileDeviceType>(this.detectDeviceType());
  public deviceType$ = this.deviceTypeSubject.asObservable();

  private isMobileSubject = new BehaviorSubject<boolean>(this.deviceTypeSubject.getValue() !== 'unknown');
  public isMobile$ = this.isMobileSubject.asObservable();

  constructor() {
    fromEvent(window, 'resize')
      .pipe(
        debounceTime(200),
        map(() => this.detectDeviceType())
      )
      .subscribe(newDeviceType => {
        if (newDeviceType !== this.deviceTypeSubject.getValue()) {
          this.deviceTypeSubject.next(newDeviceType);
          this.isMobileSubject.next(newDeviceType !== 'unknown');
        }
      });
  }

  private getUserAgent(): string {
    return navigator.userAgent.toLowerCase();
  }

  private detectDeviceType(): MobileDeviceType {
    const userAgent = this.getUserAgent();

    if (/android/.test(userAgent)) {
      return 'android';
    }

    if (/iphone|ipod|ipad|intel mac os x/.test(userAgent)) {
      return 'ios';
    }

    return 'unknown';
  }
}
