import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfigurationModule } from '../configuration/configuration.module';
import { ActiveSubstanceTypeDictionaryService } from './definitions/active-substance-type/active-substance-type-dictionary.service';
import { AgrofagTypeDictionaryService } from './definitions/agrofag-type/agrofag-type-dictionary.service';
import { CultivationCropDictionaryService } from './definitions/cultivation/cultivation-crop-dictionary.service';
import { CultivationInterCropDictionaryService } from './definitions/cultivation/cultivation-inter-crop-dictionary.service';
import { FertilizerTypeDictionaryService } from './definitions/fertilizer-type/fertilizer-type-dictionary.service';
import { MachineTypeDictionaryService } from './definitions/machine-type/machine-type-dictionary.service';
import { TreatmentTypeDictionaryService } from './definitions/treatment-type/treatment-type-dictionary.service';
import { DICTIONARIES_SERVICES, DictionariesService } from './dictionaries.service';

const ACTIVE_SUBSTANCE_TYPE_DICT = {
  provide: DICTIONARIES_SERVICES,
  multi: true,
  useClass: ActiveSubstanceTypeDictionaryService
};
const AGROFAG_TYPE_DICT = {
  provide: DICTIONARIES_SERVICES,
  multi: true,
  useClass: AgrofagTypeDictionaryService
};
const CULTIVATION_CROP_DICT = {
  provide: DICTIONARIES_SERVICES,
  multi: true,
  useClass: CultivationCropDictionaryService
};
const MACHINE_TYPE_DICT = {
  provide: DICTIONARIES_SERVICES,
  multi: true,
  useClass: MachineTypeDictionaryService
};
const CULTIVATION_INTER_CROP_DICT = {
  provide: DICTIONARIES_SERVICES,
  multi: true,
  useClass: CultivationInterCropDictionaryService
};
const FERTILIZER_TYPE_DICT = {
  provide: DICTIONARIES_SERVICES,
  multi: true,
  useClass: FertilizerTypeDictionaryService
};
const TREATMENT_TYPE_DICT = {
  provide: DICTIONARIES_SERVICES,
  multi: true,
  useClass: TreatmentTypeDictionaryService
};

@NgModule({
  declarations: [],
  imports: [CommonModule, ConfigurationModule],
  providers: [
    DictionariesService,
    ACTIVE_SUBSTANCE_TYPE_DICT,
    AGROFAG_TYPE_DICT,
    CULTIVATION_CROP_DICT,
    CULTIVATION_INTER_CROP_DICT,
    FERTILIZER_TYPE_DICT,
    TREATMENT_TYPE_DICT,
    MACHINE_TYPE_DICT
  ]
})
export class DictionariesModule {}
