import { ElementRef, Injectable } from '@angular/core';
import { AppConfigurationService } from 'farmcloud-core';
import { Loader } from 'google-maps';
import { CENTER_OF_POLAND_LAT_LNG } from '../config/map-config';

@Injectable({ providedIn: 'root' })
export class GoogleMapsLoaderService {
  private loadingScriptPromise: Promise<void> | null = null;
  private isScriptLoaded = false;

  private readonly DEFAULT_ZOOM = 7;

  constructor(private appConfigurationService: AppConfigurationService) {}

  async initMap(mapDiv: ElementRef, tilt: number): Promise<google.maps.Map> {
    await this.loadGoogleMaps();
    const mapOptions = this.getDefaultMapOptions(tilt);
    const googleMap = new google.maps.Map(mapDiv.nativeElement, mapOptions);
    return new Promise<google.maps.Map>(resolve => {
      const listener = googleMap.addListener('tilesloaded', () => {
        google.maps.event.removeListener(listener);
        resolve(googleMap);
      });
    });
  }

  public async loadGoogleMaps(): Promise<void> {
    if (this.isScriptLoaded) {
      return;
    }

    if (this.loadingScriptPromise) {
      return this.loadingScriptPromise;
    }

    const apiKey = this.appConfigurationService.configuration.google_maps_api_key;

    if (!apiKey) {
      throw new Error('Google Maps API key is missing');
    }

    const loader = new Loader(apiKey, {
      libraries: ['drawing', 'places', 'geometry']
    });

    this.loadingScriptPromise = loader
      .load()
      .then(() => {
        this.isScriptLoaded = true;
      })
      .catch(error => {
        console.error('Error loading Google Maps script', error);
        throw error;
      });

    return this.loadingScriptPromise;
  }

  private getDefaultMapOptions(tilt: number): google.maps.MapOptions {
    return {
      center: { lat: CENTER_OF_POLAND_LAT_LNG.lat, lng: CENTER_OF_POLAND_LAT_LNG.lng },
      zoom: this.DEFAULT_ZOOM,
      mapTypeId: 'hybrid',
      streetViewControl: false,
      tilt,
      styles: [
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }]
        }
      ]
    };
  }
}
