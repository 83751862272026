<header
  class="mobile-header"
  (click)="navigateToAppStoreLink()">
  <div class="description">
    <img src="../assets/images/farm-portal-mobile-icon.png" />
    <div class="description-title">
      <h3 class="app-name">{{ 'APPLICATION.MOBILE_INFO_HEADER.appName' | translate }}</h3>
      <p>{{ 'APPLICATION.MOBILE_INFO_HEADER.appDescription' | translate }}</p>
    </div>
  </div>

  <span class="download-link"><i class="ph ph-download-simple"></i></span>
</header>
