import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { AuthService } from '@farm-portal/core/auth/services/auth.service';
import { SpinnerService } from 'farmcloud-core';

@Component({
  selector: 'app-load-asl-user-data',
  templateUrl: './load-asl-user-data.component.html',
  styleUrls: ['./load-asl-user-data.component.scss']
})
export class LoadAslUserDataComponent implements OnInit, OnDestroy {
  private tokenId: string;
  private getParamSub: Subscription;
  private loginSub: Subscription;

  constructor(
    private readonly authService: AuthService,
    private readonly activeRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly spinnerService: SpinnerService
  ) {}

  ngOnInit() {
    this.getParamSub = this.activeRoute.queryParams.pipe(map(params => (this.tokenId = params.tokenId))).subscribe({
      next: () => {
        if (this.tokenId && this.tokenId !== null) {
          this.doLogin();
        } else {
          this.router.navigate(['/auth/login']);
        }
      },
      error: () => this.router.navigate(['/auth/login'])
    });
  }

  private doLogin() {
    this.spinnerService.display();

    this.loginSub = this.authService
      .loginAslToken(this.tokenId)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe({
        next: () => this.router.navigate(['']),
        error: () => this.router.navigate(['/auth/login'])
      });
  }

  ngOnDestroy(): void {
    this.getParamSub?.unsubscribe();
    this.loginSub?.unsubscribe();
  }
}
