import { ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { InputBaseComponent } from '../input.base';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-input-ng-select',
  templateUrl: './input-ng-select.component.html',
  styleUrls: ['./../input.base.scss']
})
export class InputNgSelectComponent<T> extends InputBaseComponent implements OnDestroy {
  @Input() public options: T[] = [];
  @Input() public bindLabel = 'label';
  @Input() public bindValue = 'value';
  @Input() public clearable = true;
  @Input() public groupBy = '';
  @Input() public multiple = false;
  @Input() public searchByField = '';
  @Input() public translateKey = '';
  @Input() public isDictionary = false;
  @Input() public tooltipTranslateKey: string | null = null;
  @Input() public readonly: boolean | undefined = undefined;
  @Input() public placeholderTranslateKey = '';

  private onLangChangeSubscription: Subscription;

  constructor(
    private translateService: TranslateService,
    private cdRef: ChangeDetectorRef
  ) {
    super();

    this.onLangChangeSubscription = this.translateService.onLangChange.subscribe(() => {
      this.cdRef.detectChanges();
    });
  }

  ngOnDestroy(): void {
    if (this.onLangChangeSubscription) {
      this.onLangChangeSubscription.unsubscribe();
    }
  }

  public customSearchFn = (term: string, item: T) => {
    term = term.toLowerCase();
    const value = item[this.bindLabel as keyof T];
    const displayTextValue = this.get(value);
    return displayTextValue.toLowerCase().indexOf(term) > -1;
  };

  public getDisplayText(item: T): string {
    const value = item[this.bindLabel];
    if (this.translateKey) {
      return `${this.translateKey}.${value}`;
    }

    return value;
  }

  public getDisplayGroupText(item: T): string {
    const value = item[this.groupBy];
    if (this.translateKey) {
      return `${this.translateKey}.${value}`;
    }

    return value;
  }

  public getPlaceholder(): string {
    if (!this.placeholderTranslateKey) {
      return 'APPLICATION.FORM.select';
    }

    return this.placeholderTranslateKey;
  }

  private get(value: unknown): string {
    if (this.translateKey) {
      return this.translateService.instant(`${this.translateKey}.${value}`);
    }

    if (this.isDictionary) {
      return this.translateService.instant(`${value}`);
    }

    return value as string;
  }
}
