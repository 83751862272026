<div
  ngbDropdown
  class="menu-nav-language-switcher__container">
  <button
    type="button"
    class="menu-nav-language-switcher__btn btn d-flex align-items-center"
    id="menuNavLanguageDropDown"
    ngbDropdownToggle>
    <label *ngIf="displaySelectedLang"> {{ 'APPLICATION.language' | translate }}</label>
    <div class="icon-container">
      <i [ngClass]="['flag-icon', 'flag-icon-' + selectedLanguage.key]"></i>
      <span
        *ngIf="displaySelectedLang"
        class="pl-1"
        >{{ selectedLanguage.key | enumTranslateKey: 'APPLICATION.LANGUAGES' | translate }}</span
      >
    </div>
  </button>
  <div
    aria-labelledby="menuNavLanguageDropDown"
    ngbDropdownMenu
    class="menu-nav-language-switcher__dropdown-menu">
    <button
      type="button"
      ngbDropdownItem
      class="menu-nav-language-switcher__menu-icon"
      (click)="changeLanguage(language.key)"
      *ngFor="let language of languages; trackBy: trackByFn">
      <i
        class="menu-nav-language-switcher__menu-icon"
        [ngClass]="['flag-icon', 'flag-icon-item', 'flag-icon-' + language.key]"></i>
      <span>{{ language.key | enumTranslateKey: 'APPLICATION.LANGUAGES' | translate }}</span>
    </button>
  </div>
</div>
