import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class PhoneValidator {
  static PATTERN = '^\\+[1-9]\\d{1,14}$';

  static format(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null;
      }

      if (value.match(this.PATTERN)) {
        return null;
      }

      return { phoneInvalid: true };
    };
  }
}
