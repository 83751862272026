import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AcceptInvitationDto } from '../models/accept-invitation.dto';
import { ConfirmEmailInputDto } from '../models/confirm-email-input.model';
import { InvitationFormDto } from '../models/invitation-form.dto';
import { ResetPasswordDto } from '../models/reset-password.dto';
import { SendEmailActivationLinkInputDto } from '../models/send-email-activation-link-input.model';
import { StorageKey } from '../models/storage-key.enum';
import { UserCredentials } from '../models/user-credentials.dto';
import { UserRegisterDto } from '../models/user-register.dto';
import { AuthProxyService } from './auth-proxy.service';
import { AuthStorageService } from './auth-storage.service';
import { AuthStoreService } from './auth-store.service';
import { AuthApiClientService, RefreshTokenInputDto } from '@as/api';

//NOTE This is a temporary solution, is should be replaced with import (see lines above)
const FARM_PORTAL_TOKEN = 'farmportal';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private readonly storageService: AuthStorageService,
    private readonly authProxyService: AuthProxyService,
    private readonly authStoreService: AuthStoreService,
    private readonly authApi: AuthApiClientService,
    private router: Router
  ) {}

  public login(userCredentials: UserCredentials): Observable<void> {
    return this.authProxyService.token(userCredentials).pipe(switchMap(res => this.handleTokenResponse(res.accessToken, res.refreshToken)));
  }

  public loginAslToken(token: string): Observable<void> {
    return this.authProxyService.loginAsl(token).pipe(switchMap(res => this.handleTokenResponse(res.accessToken, res.refreshToken)));
  }

  public logout(): void {
    this.authStoreService.updateUserNotLogged();
  }

  public register(input: UserRegisterDto): Observable<unknown> {
    return this.authProxyService.register(input);
  }

  public resetPassword(data: ResetPasswordDto) {
    return this.authProxyService.resetPassword(data);
  }

  public refreshTokenOnOrganizationChange(selectedOrganizationId: string): Observable<void> {
    const getAccessToken = this.storageService.getItem(StorageKey.ACCESS_TOKEN_KEY);
    const getRefreshToken = this.storageService.getItem(StorageKey.REFRESH_TOKEN_KEY);

    return forkJoin([getAccessToken, getRefreshToken]).pipe(
      switchMap(([accessToken, refreshToken]) => {
        const refreshTokenInput: RefreshTokenInputDto = {
          accessToken,
          host: FARM_PORTAL_TOKEN,
          refreshToken,
          organizationId: selectedOrganizationId
        };
        return this.authApi.refreshToken(refreshTokenInput);
      }),
      switchMap(res => this.handleTokenResponse(res.accessToken, res.refreshToken))
    );
  }

  public confirmEmail(code: ConfirmEmailInputDto) {
    return this.authProxyService.confirmEmail(code);
  }

  public sendEmailActivationLink$(input: SendEmailActivationLinkInputDto) {
    return this.authProxyService.sendEmailActivationLink$({
      email: input.email,
      host: FARM_PORTAL_TOKEN
    } as SendEmailActivationLinkInputDto);
  }

  public clearLoginData() {
    this.authStoreService.updateUserNotLogged();
  }

  public navigateToLoginPage(partnerId?: string) {
    return this.router.navigate(['auth', 'login'], { queryParams: { partnerId: partnerId } });
  }

  private handleTokenResponse(accessToken: string, refreshToken: string): Observable<void> {
    return this.authStoreService.updateAuthTokens(accessToken, refreshToken).pipe(
      switchMap(() => this.authApi.getUserProfile()),
      switchMap(userProfile => this.authStoreService.updateUserData(userProfile)),
      map(() => null)
    );
  }

  public acceptInvitation(acceptInvitation: AcceptInvitationDto): Observable<void> {
    return this.authProxyService.acceptInvitation(acceptInvitation);
  }

  public getInvitation(invitationId: string): Observable<InvitationFormDto> {
    return this.authProxyService.getInvitation(invitationId);
  }
}
