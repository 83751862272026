import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigurationService } from 'farmcloud-core';
import { ResetPasswordDto } from '../models/reset-password.dto';
import { TokenAuthResultDto } from '../models/token-auth-result.dto';
import { UserCredentials } from '../models/user-credentials.dto';
import { UserRegisterDto } from '../models/user-register.dto';
import { ConfirmEmailInputDto } from '../models/confirm-email-input.model';
import { SendEmailActivationLinkInputDto } from '../models/send-email-activation-link-input.model';
import { AcceptInvitationDto } from '../models/accept-invitation.dto';
import { InvitationFormDto } from '../models/invitation-form.dto';

export interface LoginSocialDto {
  authProvider: ExternalLoginProviderType;
  host: string;
}

export enum ExternalLoginProviderType {
  MICROSOFT = 'Microsoft',
  FACEBOOK = 'Facebook',
  GOOGLE = 'Google',
  APPLE = 'Apple'
}

@Injectable({
  providedIn: 'root'
})
export class AuthProxyService {
  private readonly baseTokenAuthUrl: string;
  private readonly baseUserAuthUrl: string;
  private readonly baseInvitationUrl: string;

  constructor(
    private http: HttpClient,
    config: AppConfigurationService
  ) {
    this.baseTokenAuthUrl = `${config.configuration.api_url}/api/TokenAuth`;
    this.baseUserAuthUrl = `${config.configuration.api_url}/api/UserAuth`;
    this.baseInvitationUrl = `${config.configuration.api_url}/api/Invitation`;
  }

  public loginAsl(token: string) {
    const url = `${this.baseTokenAuthUrl}/AslAuthenticate`;
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.append('AslToken', JSON.stringify(token));

    const options = { headers };
    return this.http.post<TokenAuthResultDto>(
      url,
      {
        authProvider: ExternalLoginProviderType.MICROSOFT,
        host: 'farmportal'
      } as LoginSocialDto,
      options
    );
  }

  public token(tokenRequestDto: UserCredentials): Observable<TokenAuthResultDto> {
    const url = `${this.baseTokenAuthUrl}/Authenticate`;
    return this.http.post<TokenAuthResultDto>(url, tokenRequestDto);
  }

  public register(input: UserRegisterDto): Observable<unknown> {
    const url = `${this.baseUserAuthUrl}/Register`;
    return this.http.post(url, input || {});
  }

  public resetPassword(data: ResetPasswordDto) {
    const url = `${this.baseUserAuthUrl}/ResetPassword`;
    return this.http.post(
      url,
      {
        ...data
      } || {}
    );
  }

  public confirmEmail(input: ConfirmEmailInputDto) {
    const url = `${this.baseUserAuthUrl}/ConfirmEmail`;
    return this.http.post(url, input || {});
  }

  public sendEmailActivationLink$(input: SendEmailActivationLinkInputDto) {
    const url = `${this.baseUserAuthUrl}/SendEmailActivationLink`;
    return this.http.post(url, input || {});
  }

  public acceptInvitation(data: AcceptInvitationDto): Observable<void> {
    const url = `${this.baseInvitationUrl}/AcceptInvitation`;
    return this.http.post<void>(url, data);
  }

  public getInvitation(invitationId: string): Observable<InvitationFormDto> {
    const url = `${this.baseInvitationUrl}/${invitationId}`;
    return this.http.get<InvitationFormDto>(url);
  }
}
