<div
  class="wrapper main-spinner"
  [ngClass]="{ 'nav-collapsed': iscollapsed, 'menu-collapsed': iscollapsed, 'wrapper-mobile': checkDeviceService.isMobile$ | async }"
  [dir]="'ltr'">
  <div
    appSidebar
    class="app-sidebar"
    [attr.data-image]="'assets/img/sidebar-bg/01.jpg'">
    <app-sidebar
      [menuGroups]="routes"
      [logoUrl]="'../assets/images/FarmPortal_logo.svg'"
      [user]="user"
      (logoutEmit)="logout()"
      [userClaims]="groupClaims"
      [isMobile]="checkDeviceService.isMobile$ | async">
      <div style="height: 100px">
        <a
          *ngIf="reseller && reseller.logoSmallImage != ''"
          partnerLogo
          href="{{ reseller.homepage }}">
          <img
            class="logo-partner img-thumbnail"
            src="../assets/farmportal-logo.png"
            alt="{{ reseller.name }}" />
        </a>
      </div>
    </app-sidebar>

    <div class="sidebar-background"></div>
  </div>
  <app-navbar [logoUrl]="'assets/images/FarmPortal_logo.svg'">
    <ul class="navbar-nav">
      <li class="nav-item nav-item-organizations">
        <app-farm-nav-item class="nav-item-organizations_flex"></app-farm-nav-item>
      </li>
      <li
        class="nav-item"
        [routerLinkActive]="['nav-item-active']">
        <a
          routerLink="/contact"
          class="nav-item-contact-btn btn btn-icon">
          <i class="nav-item-contact-icon ph-duotone ph-phone color-primary"></i>
        </a>
      </li>
      <li class="nav-item">
        <app-navbar-notifications></app-navbar-notifications>
      </li>
      <li
        class="nav-item"
        routerLink="/messages"
        [routerLinkActive]="['nav-item-active']"
        *ngIf="hasAccess('Organization.Messages')">
        <app-message-unread-notification></app-message-unread-notification>
      </li>
      <li class="nav-item">
        <app-lang-selector></app-lang-selector>
      </li>
    </ul>
  </app-navbar>
  <div class="main-panel">
    <div class="main-content">
      <div class="px-3">
        <router-outlet></router-outlet>
        <div
          class="provider-container"
          *ngIf="hasAccess('Organization.Partners.PFZ')">
          <span>{{ 'APPLICATION.PROVIDERS.pfzInfo' | translate }}</span>
          <img src="assets/images/pfz-logo.png" />
          <img src="assets/images/fundusze-promocji-logo.png" />
        </div>
      </div>
    </div>
  </div>
</div>
