<div class="page-loading spinner-backdrop">
  <div>
    <div class="circle-spinner loading-icon">
      <div></div>
    </div>
    <div class="spinner-text">
      {{ 'APPLICATION.loadingData' | translate }}
    </div>
  </div>
</div>
