import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule, OnDestroy } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ActivationStart, Router } from '@angular/router';
import { API_LOGIN_DATA_SERVICE_TOKEN, API_URL_SERVICE_TOKEN, ORGANIZATION_SERVICE_TOKEN } from '@as/api';
import { ApiDataProviderService } from '@farm-portal/core/api-data-provider.service';
import { LayoutCoreModule } from '@farm-portal/core/layout';
import { NotFoundComponent } from '@farm-portal/notFound.component';
import { LayoutModule } from '@farm-portal/shared/modules/layout/layout.module';
import { TranslationsLoader } from '@farm-portal/translations-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
  AppConfigurationService,
  AppInitService,
  CommonModule,
  ConfigurationModule,
  DictionariesModule,
  DictionaryPipe,
  ErrorsHandler,
  initAppConfiguration,
  LoggerModule,
  LongDateTimePipe,
  NanDecimalPipe,
  NotificationModule,
  ShortDatePipe,
  SpinnerModule,
  SupportedLanguages,
  TRANSLATE_MODULE_OPTIONS_CONFIG,
  TranslateModuleOptions,
  TranslateService,
  TranslationModule
} from 'farmcloud-core';
import { Subscription } from 'rxjs';
import { combineLatestWith, filter, map } from 'rxjs/operators';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './core/auth/auth.module';
import { ClarityModule } from './core/clarity/clarity.module';
import { MobileInfoHeaderModule } from './shared/modules/mobile-info-header/mobile-info-header.component';
import { TranslationInitializationService } from './translation-initialization.service';

const DEFAULT_TRANSLATE_MODULE_OPTIONS_CONFIG: TranslateModuleOptions = {
  defaultLanguage: SupportedLanguages.PL
};

export function initializeTranslations(translationInitService: TranslationInitializationService) {
  return (): Promise<void> => {
    return translationInitService.initializeTranslations();
  };
}

@NgModule({
  declarations: [AppComponent, NotFoundComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    AuthModule.forRoot(),
    LayoutCoreModule,
    NotificationModule,
    LoggerModule,
    ConfigurationModule,
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationsLoader,
        deps: [HttpClient, AppConfigurationService]
      }
    }),
    TranslationModule,
    ClarityModule,
    DictionariesModule,
    SpinnerModule,
    LayoutModule,
    MobileInfoHeaderModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initAppConfiguration,
      deps: [AppInitService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeTranslations,
      deps: [TranslationInitializationService],
      multi: true
    },
    {
      provide: TRANSLATE_MODULE_OPTIONS_CONFIG,
      useValue: DEFAULT_TRANSLATE_MODULE_OPTIONS_CONFIG
    },
    {
      provide: ErrorHandler,
      useClass: ErrorsHandler
    },
    {
      provide: API_URL_SERVICE_TOKEN,
      useClass: ApiDataProviderService
    },
    {
      provide: ORGANIZATION_SERVICE_TOKEN,
      useClass: ApiDataProviderService
    },
    {
      provide: API_LOGIN_DATA_SERVICE_TOKEN,
      useClass: ApiDataProviderService
    },
    NanDecimalPipe,
    DictionaryPipe,
    LongDateTimePipe,
    ShortDatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule implements OnDestroy {
  private pageTitleSub: Subscription;

  constructor(
    private router: Router,
    private readonly titleService: Title,
    private readonly translateService: TranslateService
  ) {
    //set title  whenever route or language changes
    this.pageTitleSub = this.translateService
      .onLangChange()
      .pipe(
        combineLatestWith(
          this.router.events.pipe(
            filter(event => event instanceof ActivationStart && event.snapshot.data['title']),
            map(event => {
              return (event as ActivationStart).snapshot.data['title'] as string;
            })
          )
        )
      )
      .subscribe(([, pageTitle]) => {
        const title = this.translateService.translate(pageTitle);
        this.titleService.setTitle(`${title} - FarmPortal`);
      });
  }

  ngOnDestroy() {
    if (this.pageTitleSub) {
      this.pageTitleSub.unsubscribe();
    }
  }
}
